$isMobile: 'screen and (min-width: 300px) and (max-width: 768px)';

:root {
  --bottom-safe-area-pwa: 22px;

  --length-x1: 0.6364rem;
  --length-x2: calc(var(--length-x1) * 2);
  --length-x3: calc(var(--length-x1) * 3);
  --length-x4: calc(var(--length-x1) * 4);
  --length-x5: calc(var(--length-x1) * 5);
  --length-x6: calc(var(--length-x1) * 6);
  --length-x7: calc(var(--length-x1) * 7);
  --length-x8: calc(var(--length-x1) * 8);
  --length-x9: calc(var(--length-x1) * 9);
  --length-x10: calc(var(--length-x1) * 10);
  --length-x12: calc(var(--length-x1) * 12);
  --length-x13: calc(var(--length-x1) * 13);
  --length-x16: calc(var(--length-x1) * 16);
  --length-x25: calc(var(--length-x1) * 25);
  --card-padding: 20px;

  --padding1: 0.4545rem;
  --padding2: 1.273rem;
  --padding3: 1.818rem;

  --context-menu-z-index: 2000;
  --usual-fs: 11px;
  --header-fs: calc(var(--usual-fs) + 2px);

  --pmk-font-family: 'pmk-font';

  --overlay-container-z-index: 10000;

  --toolbar-height: var(--length-x8);
  --toolbar-padding-bottom: 0px;

  --icon-small: 0.7273rem;
  --icon-normal: 0.9091rem;
  --icon-big: 1.091rem;
  --icon-max: 1.273rem;

  --card-header-height: var(--length-x8);

  --licence-padding-bottom: 0px;

  @media #{$isMobile} {
    --usual-fs: 12px;
  }

  @media screen and (orientation: landscape) {
    --toolbar-height: var(--length-x7);
  }
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-size {
  width: 100%;
  height: 100%;
}
