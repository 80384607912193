.app-loading-container-background {
  background-color: #323E44;
}

.app-loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;

  --size-planet: calc(100vw / 10);

  .app-loading {
    --width-sputnik: calc(var(--size-planet) - calc(var(--size-planet) / 2));
    --height-sputnik: calc(var(--width-sputnik) / 1.052);
    --width-cloud: var(--size-planet);
    --height-cloud: calc(var(--width-cloud) / 3);
    position: relative;
    height: var(--size-planet);
    width: var(--size-planet);

    .app-planet {
      top: 0;
      left: 0;
      position: absolute;
      background: url('^assets/icons/planet.svg') no-repeat;
      height: var(--size-planet);
      width: var(--size-planet);
      background-size: var(--size-planet);
    }

    .app-sputnik {
      position: absolute;
      display: flex;
      height: calc(var(--size-planet) + var(--width-sputnik) + calc(var(--size-planet) / 8));
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform-origin: calc(var(--size-planet) / 2) calc(var(--size-planet) / 2);
      animation: app-moving 10s infinite linear;

      .sputnik {
        background: url('^assets/icons/sputnik.png') no-repeat;
        background-size: var(--width-sputnik);
        width: var(--width-sputnik);
        height: var(--height-sputnik);
      }
    }

    .app-cloud {
      position: absolute;
      top: 0;
      left: 0;
      height: var(--size-planet);
      display: flex;
      justify-content: center;
      align-items: center;
      animation: app-cloud 10s infinite linear;
      animation-direction: alternate;

      .cloud {
        width: var(--width-cloud);
        height: var(--height-cloud);
        background: url('^assets/icons/cloud.png') no-repeat;
        background-size: var(--width-cloud);
      }
    }
  }

  @keyframes app-cloud {
    from {
      transform: translate3d(calc(var(--width-cloud) / -4), 0, 0);
    }
    to {
      transform: translate3d(calc(var(--width-cloud) / 4), 0, 0);
    }
  }

  @keyframes app-moving {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes app-moving {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes app-moving {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes app-moving {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-ms-keyframes app-titlePulse {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
