@import '@angular/cdk/overlay-prebuilt.css';

@import url('../../node_modules/ngx-toastr/toastr.css');
@import './styles/proportions';
@import './styles/load_anim';
@import './styles/load_anim_local';
@import './styles/tooltip';
@import './styles/shifter-tree';
@import './styles/color-blocks';
@import './styles/popup';
@import './styles/label-description';

@import "../global/ui/card/card-theme";
@import "../global/ui/list/list-theme";
@import "../global/ui/forms/datepicker/datepicker-theme";
@import "../global/ui/forms/timepicker/timepicker-theme";
@import "../global/ui/forms/search/search-theme";

@font-face {
  font-family: 'pmk-font';
  src: local('SFUIDisplayRegular'),
    local('SF UI Display Regular'),
    local('SF-UI-Display-Regular'),
    local('SF_UI_Display_Regular'),
    url('./assets/fonts/SF_UI_Display_Regular.otf') format("opentype");
}

@font-face {
  font-family: 'pmk-font-bold';
  src: local('SFUIDisplaySemibold'),
    local('SF UI Display Semibold'),
    local('SF-UI-Display-Semibold'),
    local('SF_UI_Display_Semibold'),
    url('./assets/fonts/SF_UI_Display_Semibold.otf') format("opentype");
}

@font-face {
  font-family: 'timesNewRoman';
  src: local('TimesNewRoman'),
    local('Times New Roman'),
    local('Times-New-Roman'),
    local('Times_New_Roman'),
    url('./assets/fonts/TimesNewRoman.otf') format("opentype");
}

body {
  position: relative;

  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #263238;

  touch-action: manipulation;
}

*:not(.html-content-container *) {
  padding: 0;
  margin: 0;
  user-select: none;
  outline: none;

  font-family: var(--pmk-font-family), sans-serif;
  font-size: var(--usual-fs);
}

input {
  cursor: text;
}

div,
span,
p,
li {
  text-rendering: geometricPrecision;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'pmk-font', sans-serif;
}

input {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

button {
  cursor: pointer;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

html.main-html-element {
  position: fixed;
}

@media print {
  html {
    overflow: auto;
  }

  body {
    position: relative;
    height: auto;
  }
}

.cdk-overlay-container {
  z-index: 2000;
}

.pmk-ui-card-title {
  font-family: 'pmk-font-bold';
}

.cluster-host {
  position: relative;
  --circle-offset-multi: 3;
  --circle-offset-sum: 0;
}

.cluster-host:hover {
  --circle-offset-sum: 3;
}

.cluster-host .cluster-circle-element {
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin: auto;
    color: #fff;
  }
}

.cluster-circle-element {
  --result-offset: calc(var(--circle-index) * var(--circle-offset-multi) * -1px);
  transition: transform 125ms linear;
  background-color: var(--circle-bgc);
  transform: translateY(calc(var(--result-offset) + var(--circle-offset-sum) * -1px));
}

.cluster-circle-element:nth-child(1) {
  transform: translateY(var(--result-offset));
}

.leaflet-shadow-pane {
  z-index: 510;
}

.leaflet-marker-pane {
  z-index: 500;
}

.ripple {
  overflow: hidden;
}

.ripple-effect {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  animation-name: ripple-animation;
  animation-duration: 2s;
}

@keyframes ripple-animation {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(100);
    opacity: 0;
  }
}

/*temp*/
.prunecluster {
  font-size: 12px;
  border-radius: 20px;
  transition: all 0.3s linear;
}

.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-icon,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-shadow,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-markercluster-icon {
  transition: transform 0.3s cubic-bezier(0,0,0.25,1);
}
.prunecluster div {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 50%;
}
.prunecluster div span {
  line-height: 30px;
}

.prunecluster-small {
  background-color: #b5e28c;
  background-color: rgba(181, 226, 140, 0.6);
}

.prunecluster-small div {
  width: 28px;
  height: 28px;
  background-color: #6ecc39;
  background-color: rgba(110, 204, 57, 0.6);
}

.prunecluster-small div span {
  line-height: 28px;
}

.prunecluster-medium {
  background-color: #f1d357;
  background-color: rgba(241, 211, 87, 0.6);
}

.prunecluster-medium div {
  background-color: #f0c20c;
  background-color: rgba(240, 194, 12, 0.6);
}

.prunecluster-large {
  background-color: #fd9c73;
  background-color: rgba(253, 156, 115, 0.6);
}

.prunecluster-large div {
  width: 34px;
  height: 34px;
  background-color: #f18017;
  background-color: rgba(241, 128, 23, 0.6);
}

.prunecluster-large div span {
  line-height: 34px;
}

.leaflet-marker-icon {
  border-radius: 0 50% 50% 50%;
}

.select-marker-animation {
  border-radius: 0 50% 50% 50%;
  animation: select-animation var(--time);
}

.select-cluster-animation {
  --animation-color: #2196f3;
  border-radius: 50%;
  animation: select-animation var(--time);
}

.tracking-object-marker.is-selected-tracking-object img.substrate-url {
  box-shadow: 0 0 0 2px white;
  border-radius: 0 50% 50% 50%;
}

.tracking-object-cluster-marker .is-selected-tracking-object {
  box-shadow: 0 0 0 2px white;
}

@keyframes select-animation {
  0% {
    box-shadow: 0 0 35px -10px var(--animation-color);
  }
  50% {
    box-shadow: 0 0 35px 30px var(--animation-color);
  }
  100% {
    box-shadow: 0 0 35px -10px var(--animation-color);
  }
}

.popup-card-cluster {
  width: 175px;
  pointer-events: all;
  cursor: default;

  .leaflet-popup-content-wrapper {
    padding: var(--length-x1);

    .leaflet-popup-content {
      width: 100%;
    }

    .popup-list-container {
      position: relative;
      --height: 144px;
      --height-item: 29px;
      max-height: var(--height);
      height: calc(var(--height-item) * var(--size-item));
      .popup-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        max-height: var(--height);
        overflow: auto;
        width: 105%;
        top: 0;
        left: 0;

        .list-item {
          width: calc(100% - 2%);
          height: var(--length-x4);
          min-height: var(--length-x4);
          border-bottom: var(--default-border);
          display: flex;
          align-items: center;
          justify-content: center;

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            background-color: var(--hover);
          }

          .item-text {
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.2em;
            white-space: nowrap;
            max-width: 95%;
          }
        }
      }
    }
  }
}

.pmk-ui-scrollbar {
  scrollbar-color: var(--scrollbar-carriage) var(--scrollbar-background);
  scrollbar-width: thin;
}

.pmk-ui-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.pmk-ui-scrollbar::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.pmk-ui-scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-carriage);
  border-radius: 100px;
}

.pmk-ui-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-carriage);
}

.pmk-ui-scrollbar::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-carriage);
}

.pmk-ui-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
  border-radius: 100px;
  opacity: 0.7;
}

.pmk-ui-scrollbar::-webkit-scrollbar-track:hover {
  background: var(--scrollbar-background);
  opacity: 0.7;
}

.pmk-ui-scrollbar::-webkit-scrollbar-track:active {
  background: var(--scrollbar-background);
  opacity: 0.7;
}

.pmk-ui-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.pmk-ui-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-end;
  box-sizing: content-box;
  align-items: center;
  border-radius: var(--length-x1);
  border: 0;
  height: var(--length-x4);
  width: var(--length-x4);
  font-size: var(--usual-fs);
  outline: none;
  padding: 0;
  cursor: pointer;
}

.square-select .trigger-button {
  width: var(--length-x4)!important;
  padding: 0!important;
}

.content {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  overflow-anchor: none;

  &.content-is-empty {
    display: block;
  }
}

.content-padding {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.actions-btn {
  display: flex;
  width: 100%;
  margin-top: 14px;
}

.leaflet-heatmap-pane {
  opacity: 0.65;
}

.current-location-marker {
  background-color: white;
  border-radius: 50%;
  border: 4px solid var(--select);

  --time: 1500ms;
  --animation-color: hsla(0, 0%, 0%, 0.76);
}

.popup-marker-control {
  width: fit-content;

  pointer-events: auto;

  .leaflet-popup-content-wrapper {
    display: flex;
    padding: 4px 8px;
  }

  .popup-inner {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .distance {
    margin-right: 8px;
  }

  .popup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding: 6px;

    background-color: var(--button-background-color);
    border: none;
    border-radius: 7px;

    transition: background-color 200ms ease 0s, color 200ms ease 0s, pointer-events 200ms ease 0s;
    will-change: background-color, color;
  }

  svg {
    width: var(--icon-normal);
    height: var(--icon-normal);

    fill: var(--svg-color);
    stroke: var(--svg-color);
  }
}

.popup-marker-control--polygon {
  .leaflet-popup-content {
    width: 22px !important;
  }
}

.disabled-marker {
  pointer-events: none !important;
  cursor: default !important;
}

.hidden-hint-marker {
  opacity: 0;
}

.checkbox {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--checkbox-background);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color .1s;

  cursor: pointer;

  .checkbox-indicator {
    border-radius: 2px;
  }
}

.checkbox.selected {
  background-color: var(--checkbox-background-checked);

  .checkbox-indicator {
    width: 3px;
    height: 7px;
    border-color: var(--checkbox-indicator);
    border-style: solid;
    border-width: 0 2px 2px 0;
    margin-bottom: 2px;
    margin-left: .8px;
    transform: rotate(45deg);
  }
}

.checkbox.partial {
  background-color: var(--checkbox-background-checked);

  .checkbox-indicator {
    width: 8px;
    height: 8px;
    background-color: var(--checkbox-indicator);
  }
}

.checkbox.is-disabled {
  background-color: var(--disabled);
}

.checkbox.anti-selected {
  .checkbox-indicator {

  }
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.checkbox-container .label {
  cursor: pointer;
}

.tag {
  --tag-bg-color: var(--background-color2);

  background-color: var(--tag-bg-color);
  padding: 2px 7px;
  border-radius: var(--length-x2);
  font-size: inherit;
  color: var(--tag-font-color, var(--font-color1));
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.tag.is-system-tag {
  /**
   NOTE: Убрано ограничение по длине метки
   Предпочтительно использовать длину системных
   меток не больше 20 символов (вместе с пробелами) */
  max-width: initial;
}

.wt-native-table {
  border-collapse: collapse;
  width: 100%;
  color: var(--font-color1);
}

.wt-native-table .cell {
  border: 1px solid var(--background-color2);
  padding: calc(var(--length-x1) / 1.3) var(--length-x1);
}

.wt-native-table .cell.head {
  text-align: left;
}

.wt-native-table .caption,
.wt-native-table .group-header {
  background-color: var(--background-color2);
  padding: var(--length-x1);
}

.wt-native-table .group-header {
  border: 1px solid var(--background-color2);
}

// region wt-input
.wt-input-container.field-container {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.wt-input-container.field-container {
  display: flex;
  flex-direction: column;

  position: relative;

  padding-top: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.wt-input-container.field-container label {
  color: var(--font-color2);
  position: absolute;
  transition: transform 200ms, font-size 200ms;
  pointer-events: none;

  &.is-minimized {
    transform: translateY(-100%);
    font-size: 9px;
  }
}

.wt-input-container.field-container .hint {
  margin-top: var(--length-x1);
  color: var(--font-color2);
}

.wt-input-container.field-container input {
  border: none;
  background: none;
  font-size: var(--usual-fs);
  color: var(--font-color1);
  border-bottom: 1px solid var(--hover);
  transition: border-bottom-color 500ms;

  &:focus {
    border-bottom-color: var(--focused);
  }

  &:focus + label {
    transform: translateY(-100%);
    font-size: 9px;
  }

  &.ng-invalid.ng-touched {
    border-bottom-color: var(--red-600);
  }
}

// endregion

.is-standalone {
  --toolbar-height: calc(var(--length-x8) + var(--bottom-safe-area-pwa));
  --toolbar-padding-bottom: var(--bottom-safe-area-pwa);
  --licence-padding-bottom: var(--bottom-safe-area-pwa);
}

.html-content-container {
  overflow-y: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;

  > *:first-child {
    margin-top: 0;
  }

  figure img[data-stretched="true"] {
    max-width: 100%;
  }

  figure img[data-stretched="false"] {
    max-width: 300px;
  }

  figure {
    text-align: left;
    color: var(--font-color2);
    font-size: 12px;

    @media screen and (max-width: 600px) {
      margin: 1em 0;
    }
  }

  a {
    color: var(--select);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    color: var(--font-color1);
  }

  table td,
  table th {
    border: 1px solid var(--background-color2);
    padding: calc(var(--length-x1) / 1.3) var(--length-x1);
  }

  table th {
    text-align: left;
  }

  table caption {
    background-color: var(--background-color2);
    padding: var(--length-x1);
  }

  a[data-link-like-button] {
    display: inline-block;
    background-color: var(--select);
    color: var(--font-color1);
    padding: 10px 14px;
    border-radius: 7px;
    transition: 250ms linear background-color;
  }

  a[data-link-like-button]:hover {
    text-decoration: none;
  }
}

.oldest-news-content .html-content-container figure img {
  width: 100%;
}

.marker-div-icon {
  position: relative;
}

img.tracking-object-icon-url {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}

img.substrate-url {
  margin-left: -16px;
  margin-top: -16px;
  width: 32px;
  height: 32px;
  transform-origin: 16px 16px;
  transform: var(--transform);
}

.max-interval-error-message {
  color: hsl(16deg 100% 44%);
}

.geofence.selected {
  stroke-dasharray: 20;
  animation: geofence-selected 40s linear infinite;
}

@keyframes geofence-selected {
  from {
    /*
     * NOTE(PMKMP-2032): КТО ТРОНЕТ ПОСТАНОВУ ТОТ ЗДОХНЕТ.
     * https://i.pinimg.com/474x/d5/eb/90/d5eb908c3d2ba9380cc42e614f957e66.jpg
     * Единицу измерения, нужно указывать для того, чтобы
     * этот код работал в Safari
     */
    stroke-dashoffset: 0%;
  }

  to {
    stroke-dashoffset: 100%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape){
  body {
    background-color: black;
  }
}
