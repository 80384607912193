.label-description {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: var(--length-x1);
    color: var(--font-color1);
    font-size: 18px;
    font-family: pmk-font-bold, serif;
  }

  .description {
    color: var(--font-color2);
  }
}

.inline-text {
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: fit-content;
  white-space: nowrap;
}