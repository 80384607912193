.card-header-stop {
  background-color: #42a5f5 !important;
}

.card-header-parking {
  background-color: #1565c0 !important;
}

.card-header-offline {
  background-color: #7E7E7E !important;
}
