.pmk-ui-list-item {
  color: var(--font-color1);
  border-color: var(--border-color);
}
.hovered:hover {
  background-color: var(--hover);
}

.pmk-ui-list-container-border {
  border-right-color: var(--border-color);
}

.active-item {
  background-color: var(--blue-700);
  border-color: var(--blue-700);
  color: var(--font-color1);

  &:hover {
    background-color: var(--blue-700);
    color: var(--font-color1);
  }
}

.pmk-ui-dropdown-item-disabled {
  background-color: var(--disabled);
  cursor: default;

  &:hover {
    background-color: var(--disabled);
  }
}
