@mixin clearContainer {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  opacity: 1;
}

@mixin noBorderRadius {
  border-width: 0;
  border-radius: 0;
}

.popup-card-track {
  @include clearContainer;
  @include noBorderRadius;
  min-width: 15px;
  width: 236px;
  //pointer-events: none;

  .leaflet-popup-content-wrapper {
    @include clearContainer;
    background: var(--background-color1);
    border-radius: 7px;

    .leaflet-popup-content {
      @include clearContainer;

      .popup-card-header {
        transition: background-color .3s;
        height: 56px;
        width: 236px;
        border-radius: 7px 7px 0 0;
        padding: 14px 20px;
        box-sizing: border-box;
        color: #fff;
        .popup-label {
          font-size: 13px;
          font-family: 'pmk-font-bold', bold, serif;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 1.2em;
          white-space: nowrap;
        }

        .popup-desciprion {
          font-size: 11px;
        }
      }
      .popup-content {
        display: flex;
        flex-direction: column;
        color: var(--font-color1);
        padding-bottom: 14px;

        .popup-extra-line {
          background-color: var(--background-color2);
          width: 196px;
        }

        .popup-content-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 20px;

          .content-label {
            opacity: .7;
          }

          .content-popup {

          }
        }

        .array-line-with-label-value {
          .content-label {
            width: 100%;
            opacity: 1;
          }

          .label-content {
            opacity: 0.7;
          }

          .array-line-label {
            display: flex;
            justify-content: space-between;
          }
        }

        .array-line {
          flex-direction: column;
          align-items: flex-start;
          height: 100%;

          .array-content-popup {
            width: 100%;

            .array-item-popup {
              .array-field-line {
                display: flex;
                justify-content: space-between;
                max-width: 100%;
                padding-left: var(--length-x2);
                .array-label {
                  opacity: .7;
                  max-width: 130px;
                }
              }
            }
          }
        }
      }
    }
  }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      width: 15px;
      height: 15px;
      background: var(--background-color1);
      box-shadow: none;
    }
  }

  .popup-card-header {
    height: 54px;
  }
}

.without-tip .leaflet-popup-tip-container {
  display: none;
}

.popup-card-marker {
  width: fit-content;
  pointer-events: none;
  .leaflet-popup-content {
    color: var(--font-color1);
  }
  .leaflet-popup-content-wrapper {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    padding: 0 7px;
  }
  .leaflet-popup-content {
    text-align: center;
  }
}

.leaflet-popup.zones-label {
  margin-bottom: 0;

  .leaflet-popup-content-wrapper {
    background-color: transparent;
    color: black;
    box-shadow: none;

    .leaflet-popup-content {
      margin: 0;
    }
  }

  .zones-label-main {
    text-align: center;
    color: var(--zones-label-color);
    font-size: 12px;
  }
}
