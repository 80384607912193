.pmk-ui-card {
  background-color: var(--background-color1);
  color: var(--font-color1);
  box-shadow: var(--shadow);
}
.blue-card {
  background-color: var(--blue-700);
  color: #fff;
  
  .pmk-ui-button {
    background-color: var(--background-color2);
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
  }
  
  .pmk-ui-card-subtitle {
    color: #fff;
  }
}
.accent-card {
  background-color: var(--background-color2);
}
.popup-card {
  background-color: var(--background-color2);
}

.pmk-ui-card-header-border {
  border-bottom: 1px solid var(--border-color);
}

.pmk-ui-card-subtitle {
  color: var(--font-color1);
}
