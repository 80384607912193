.pmk-ui-search {
  background-color: var(--background-color2);
  color: var(--font-color1);
  
  &::-webkit-input-placeholder {
    color: var(--font-color2);
  }
  
  &::-moz-placeholder {
    color: var(--font-color2);
  }
  
  /* Firefox 19+ */
  &:-moz-placeholder {
    color: var(--font-color2);
  }
  
  /* Firefox 18- */
  &:-ms-input-placeholder {
    color: var(--font-color2);
  }
}
