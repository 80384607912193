.leaflet-TOOLTIP-pane,
.leaflet-tooltip .pmk-marker-tooltip .leaflet-zoom-hide .leaflet-tooltip-top {
  //z-index: 800 !important;
}

.leaflet-popup-content p {
  margin: 0 !important;
}

.pmk-tooltip-container {
  border-radius: 7px !important;
  width: 236px;
  button img {
    width: 12px;
    height: 12px;
  }
  .pmk-ui-card-title, .pmk-ui-card-subtitle {
    font-weight: normal;
    line-height: normal;
    opacity: 1;
  }
  .tooltip-content {

    .pmk-ui-list-item {
      box-sizing: content-box;
      border-width: 0;
      white-space: normal;
      span {
        white-space: normal;
      }
      &.show-border {
        border-width: 1px;
      }
      &:last-of-type {
        //border-top: 1px solid #455a64;
        //outline: 1px solid purple;
        height: inherit;
        min-height: 17px;
        border-radius: 0 0 7px 7px;
        //padding-top: 14px;
        padding-bottom: 14px;
        //color: #2196f3;
      }
    }
  }
}

/*
 ******* Bootstrap popup ********
 */
.dropup,
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-clip: padding-box;

}

.dropdown-menu.show {
  display: block;
  z-index: 1;
  .popup-container {
    width: 175px;
  }
}

.dropdown-menu-to-right {
  margin-left: 10px;
}

.dropdown-menu-to-bottom {
  margin-top: 10px;
}


