
.shifter-tree-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.shifter-tree {
  --width-container-expand-tree: calc(100% - 322px);
  height: calc(100% - 76px);
}

.shifter-tree-expand {
  width: var(--width-container-expand-tree);
}

.shifter-tree-animation-expand {
  animation: tree-expand .2s;
}

.shifter-tree-constrict {
  width: 100%;
}

.shifter-tree-animation-constrict {
  width: 100%;
  animation: tree-constrict .2s;
}

@keyframes tree-constrict {
  from { width: var(--width-container-expand-tree) }
  to { width: 100% }
}

@keyframes tree-expand {
  from { width: 100% }
  to { width: var(--width-container-expand-tree) }
}
